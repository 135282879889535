.postfix-input-container {
  display: flex; 
  position: relative; 
  font: 14px Roboto; 
  font-weight: 400;

  .postfix-input {
    font: inherit;
  }
  .postfix {
    position: absolute;
    top: 8px;
    color: #B1B1B1;
    font: inherit
  }
}