.protocol-steps {
  .protocol-step-box {
    position: relative;
    width: 65px;
    height: 65px;
    font-size: 24px;
    font-weight: bold;
    color: #181818;
    text-align: center;
    line-height: 65px;
    background: #EFF3FC;
    &.active {
      background: #131F6B;
      color: #fff;
    }

    .remove-btn {
      display: none;
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background: #EB4D4D;
      top: -4px;
      right: -4px;
      line-height: 7px;
      svg {
        width: 8px;
        height: 8px;
        path {
          fill: #fff;
        }
      }
    }

    .move-icon {
      display: none;
      width: 32px;
      height: 32px;
      position: absolute;
      bottom: -16px;
      left: 50%;
      margin-left: -16px;
      line-height: initial;
      border-radius: 4px;
      overflow: hidden;
    }

    &.active:hover {
      .move-icon, .remove-btn {
        display: block;
      }
    }

    &.add-new-box {
      background: #fff;
      color: #131F6B;
      border: 1px solid #131F6B;
      font-size: 60px;
      font-weight: 100;
      line-height: 52px;
    }
  }
}

.protocol-frames {
  position: relative;
  .add-new-frames {
    position: absolute;
    right: 26px;
    top: -22px;
  }
  p {
    color: #131F6B;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .example-image {
    position: relative;
    height: 365px;
    margin-bottom: 16px;
    background: #000;
    &::before {
      content: "EXAMPLE";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      color: rgba(#fff, .5);
      line-height: 365px;
      font-weight: 600;
      font-size: 48px;
    }
    img {
      font-size: 0;
    }
  }
}

.remove-frames {
  margin-bottom: 8px;
  > div {
    font-size: 14px;
    color: #E88989;
    cursor: pointer;
    svg {
      width: 13px;
      path {
        fill: #E88989;
      }
    }
  }
}

