.users-header {
  padding: 0 120px 0 15px;
  height: 75px;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.16);
  position: fixed;
  z-index: 100;
  background: white;
  top: 0px;
  right: 0;
  left: 230px;
  width: auto;

  .filters {
    a, li {
      color: #898fb5;
      font-size: 17px;
      text-decoration: none;
      margin-bottom: -27px;
      padding-bottom: 19px;
      &.active {
        color: #131f6b;
        border-bottom: 3px solid #131f6b;
      }
    }
    &.steps {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        padding-left: 15px;
        padding-right: 15px;
        color: #131F6B;
        font-weight: bold;
        font-size: 17px;
        opacity: 0.5;
        margin-right: 20px;
        &.active {
          opacity: 1;
        }
      }
    }
  }
}

.btn-invite {
  background: #eff4fb;
  height: 38px;
  text-decoration: none;
  font-size: 17px;
  color: #131f6b;

  .fw-600 {
    font-weight: 600;
  }

  a {
    text-decoration: none;
    font-size: 17px;
    color: #131f6b;
  }
}

.search-block {
  position: relative;
  input {
    width: 255px;
    height: 38px;
    border: none;
    background: #eff4fb;
    padding: 0 30px 0 10px;
    outline: none;
  }
  svg {
    position: absolute;
    right: 10px;
    top: 12px;
  }

  &.icon-in {
    input {
      width: 100%;
    }
    svg {
      position: absolute;
      right: 7px;
    }
  }
}


.users-lists {
  ul {
    li {
      font-size: 12px;
      color: #222222;
      font-weight: bold;
      height: 61px;
      margin-bottom: 7px;
      padding: 7px;
      // &.list-header {
      //   > div {
      //     &:nth-last-child(2) {
      //       // border-right: none;
      //     }
      //   }
      // }
      > div {
        border-right: 1px solid #c6cbd4;
        &:first-child {
          padding-left: 0;
        }
        &:nth-last-child(1) {
          border-right: none;
          padding-right: 0;
        }
      }
      &.list-header {
        height: 22px;
      }

      &.list-item {
        background: #eff4fb;
        border-radius: 5px;
      }
      &.h-not-set {
        height: inherit;
        min-height: 60px;
      }
    }
  }
  .user-image {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    object-fit: cover;
    object-position: top;
  }
  &.with-scroll {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: rgba(0, 0, 0, .5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
    ul {
      li > div {
        padding: 7px;
      }
      li.list-item {
        padding: 0 7px;
        > div {
          @extend .d-flex;
          @extend .h-100;
          @extend .align-items-center;
          background: #eff4fb;
        }
      }
    }
  }
}

.user-creation-form {
  li {
    min-height: 49px;
    background: $color-blue-light;
    border-radius: 5px;
    color: #222222;
    font-weight: bold;
    margin-bottom: 7px;
    padding: 9px 9px 9px 21px;

    &:not(.hide-focus):focus-within {
      background: $color-blue-semi-light;
    }
  }

  span.result {
    font-weight: 300;
    padding: 0 13px;
  }

  .input-group {
    border: none;
    margin: 0 !important;
    &.has-error {
      input {
        border: 1px solid;
      }
    }
  }

  .input-group input, select, textarea {
    padding: 0 13px;
    height: 31px;
    border-radius: 5px;
    background: #fff;
    font-size: 14px;
    border: none;

    &::-webkit-input-placeholder {
      color: #ddd;
    }
  }

  textarea {
    resize: none;
    outline: none;
    width: 100%;
    height: 60px;
    padding-left: 13px !important;
    padding-top: 7px !important;
    &.is-small {
      height: 70px;
    }
    &.is-large {
      height: 130px;
    }
  }

  .input-with-button {
    position: relative;

    input {
      padding-right: 80px;
    }

    button {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  &.no-focus:focus-within {
    li {
      background: initial;
    }
  }
}

.subs-plan-area {
  background: #EFF4FB;
  border-radius: 5px;
  min-height: 82px;
  padding: 9px;
  font-size: 11px;
  margin: 6px 0 12px 0;
  max-height: 155px;
  overflow: scroll;
  .plan-box {
    border-radius: 5px;
    background: #FFFFFF;
    padding: 6px 12px;
    margin-bottom: 5px;
  }
  .plan-name {
    color: #242836;
  }
  button {
    text-align: right;
    min-width: 90px;
    margin-left: 50px;
    font-size: 11px;
    padding: 0;
    font-weight: bold;
    text-transform: uppercase;
  }
  .plan-dates {
    width: 150px;
    span {
      padding: 0 7px;
      &:nth-child(2){
        border-left: 2px solid #d3d4d7;
      }
    }
  }

  .plan-button-area {
    width: 200px;
  }

  form {
    margin-left: 10px;
    input {
      border: none;
      border-bottom: 1px solid;
      max-width: 80px;
      &:focus {
        outline: none;
        border-bottom: 1px solid #131f6b;
      }
    }
    &.has-key {
      button {
        min-width: initial;
        margin-left: 5px;
      }
    }
  }
}

// .users_keys_modal {
//   .subs-plan-area {
//     max-height: 210px;
//   }
// }

.user-img {
  border-radius: 100%;
  object-fit: cover;
  object-position: top;
}
