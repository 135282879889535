.entity-item {
  width: 127px;
  height: 88px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  &.small {
    width: 110px;
    height: 75px;
  }
  &.active {
    border: 1px solid #131F6B;
  }
  &:hover {
    border: 1px solid #131F6B;
  }
  .banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .no-img-banner {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: center;
    .name {
      font-size: 14px;
      font-weight: 400;
    }
    .first-letter {
      margin-top: 6px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #EFF4FB;
      color: #131F6B;
      font-size: 20px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}