.product-view-container {
  .title {
    font-size: 24px;
    font-weight: 400;
    color: #131F6B;
  }
  .regular-text {
    font-size: 14px;
    font-weight: 400;
    color: #222222;
    &.two-dimension-align {
      text-align: justify;
      text-justify: inter-word;
    }
  }

  .img-block {
    height: 334px;
    max-width: 600px;
    margin: 16px 0 24px 0;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name {
    font-size: 16px;
    font-weight: 600px;
  }
}

.details-view-block {
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: unset;
  h1 {
    font-size: 16px;
    margin-bottom: 11px;
    color: #131F6B;
  }
  img {
    object-fit: cover;
    width: 100%;
  }
}

.list-block {
  width: 100%;
  padding: 10px;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  position: relative;

  .underlined {
    color: inherit;
    text-decoration: underline;
  }
}

.dotted-row {
  border-bottom: 1px dashed #92C6CF;
  flex: 1;
  margin-bottom: 4px;
}

.details-container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .actions-block {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    .regular-action-btn {
      flex: 1;
      font-size: 12px !important;
      padding: 9px 16px 8px 16px;
      min-width: 121px;
      border-radius: 5px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.right-details-block {
  border-radius: 10px;
  margin-top: unset;
}

.pages-section {
  margin-bottom: 38px;
  .title {
    margin-bottom: 13px;
    color: #131F6B;
    font-size: 20px;
    font-weight: 700;
  }
}

.pages-block {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  .add-page-item {
    cursor: pointer;
    border: 2px solid #131F6B;
    width: 332px;
    height: 394px;
    border-radius: 10px;
    color: #131F6B;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.filter-section {
  display: flex;
  column-gap: 16px;
  margin-bottom: 20px;
}

.create-link-dialog {
  .link-row {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background-color: #EFF4FB;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .copy-icon {
    cursor: pointer;
    border-radius: 5px;
    &:hover {
      outline: 1px solid #131F6B;
    }
  }
}

.entity-item {
  width: 127px;
  height: 88px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  &.no-hover-state {
    cursor: default;
  }
  &.small {
    width: 110px;
    height: 75px;
  }
  &.mid {
    width: 127px;
    height: 88px;
  }
  &.active {
    border: 1px solid #131F6B;
  }
  &:hover {
    &:not(.no-hover-state) {
      border: 1px solid #131F6B;
    }
  }
  &.in-table {
    &:hover {
      border: none;
    }
  }
  .banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .no-img-banner {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: center;
    .name {
      font-size: 14px;
      font-weight: 400;
    }
    .first-letter {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #EFF4FB;
      color: #131F6B;
      font-size: 20px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.entity-items-block {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow-y: auto;
  max-height: 180px;

  .no-result-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    row-gap: 12px;
    .btns-block {
      display: flex;
      column-gap: 16px;
      .cancel {
        background-color: #fff;
      }
    }
  }
}

.right-side-entities {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow-y: auto;
  max-height: 180px;
}

.entities-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.entity-search-block {
  background-color: #fff;
  input {
    background-color: #fff;
    width: 100%;
    &.has-icon-left {
      padding-left: 40px;
    }
  }
  .left-icon {
    left: 12px;
  }
}